<template>
  <v-layout column>
    <LabelTextField
      class="mb-2 mt-3"
      :label="placeholder"
      :placeholder="placeholder"
      :value.sync="internalSearch"
      clearable
      hide-details
    ></LabelTextField>
    <v-layout column v-show="search">
      <PredefinedPairDelegate
        class="mx-4"
        v-for="code in codes"
        :key="code.id"
        :item="code"
        :paired.sync="internalPaired"
        card
        hideOpenIcon
      ></PredefinedPairDelegate>
    </v-layout>
  </v-layout>
</template>

<script>
import MedicalcodesService from "@/services/medicalcodes.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import _ from "lodash";
import FIELD_NAMES from "@/constants/patientcard/physio";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    paired: {},
    field: {},
    search: {},
  },
  data() {
    return {
      codes: [],
      pagination: {
        selectable: true,
        rowsPerPage: 100,
        page: 1,
        min: 1,
        nofav: true
      },
    };
  },
  watch: {
    search() {
      if (!this.internalSearch || this.internalSearch.length == 0) {
        this.codes = [];
        this.internalSearch = null;
      } else {
        this.resetPagination(this.internalSearch);
        this.fetch();
      }
    },
  },
  computed: {
    internalPaired: {
      get() {
        return this.paired;
      },
      set(value) {
        this.$emit("update:paired", value);
      },
    },
    internalSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
    placeholder() {
      if (this.field.name && FIELD_NAMES[this.field.name]) {
        const c =
          FIELD_NAMES[this.field.name].charAt(0).toLowerCase() + FIELD_NAMES[this.field.name].slice(1);
        return `Wyszukaj ${c}`;
      } else {
        return `Wyszukaj`;
      }
    },
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    PredefinedPairDelegate: () =>
      import("@/components/delegates/predefined/PredefinedPairDelegate"),
  },
  methods: {
    resetPagination(search) {
      this.pagination.search = search;
      this.pagination.page = 1;
    },
    fetch: _.debounce(function () {
      this.beforeRequest();
      MedicalcodesService.getCodes(
        this.pagination,
        this.field.name.toLowerCase()
      )
        .then((reply) => {
          let items = reply.results;
          items.forEach((element) => {
            this.$set(element, "checkable", true);
            this.$set(element, "children", []);
            this.$set(element, "name", this.field.name);
            this.$set(
              element,
              "label",
              `<b>${element.code}</b> - ${element.text}`
            );
            this.$set(element, "category", this.field.type);
            this.$set(element, "altId", element.category + "_" + element.id);
          });
          if (this.search) {
            this.codes = items;
          }
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Coś poszło nie tak.");
        });
    }, 200),
  },
};
</script>